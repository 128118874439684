<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="updateDueDateForm"
        ref="updateDueDateForm"
        class="custom-form pt-8"
        @submit.prevent="updateDueDate"
      >
        <v-row>
          <v-col cols="12">
            <v-menu
              v-model="showPicker"
              v-bind="attributes.menu"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  :value="displayedDate"
                  class="pt-0 input--date-picker"
                  label="Data realizacji"
                  :rules="showPicker ? [] : [hasDateChanged]"
                  placeholder="Wprowadź datę realizacji"
                  v-bind="{...attrs, ...attributes.textField}"
                  v-on="on"
                  @click.native="showPicker = !showPicker"
                >
                  <template #prepend-inner>
                    <Icon
                      name="date"
                      size="small"
                      class="mr-2"
                    />
                  </template>
                </v-text-field>
              </template>

              <v-date-picker
                v-model="date"
                v-bind="attributes.datePicker"
                :min="new Date().toISOString().substring(0,10)"
                @input="showPicker = false"
              />
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="updateDueDateForm"
        v-shortkey="{enter:['enter']}"
        @shortkey.native="updateDueDate"
      >
        Zapisz zmianę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import attributes from '../../const/datePickerAttrributes'
import { dateStringFormat } from '../../utils'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    allCourses: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      attributes,
      showPicker: false,
      date: null
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.courses.isProcessing,
      course: state => state.layout.dialog.item
    }),
    tableDateRange () {
      return this.$store.state.courses.dateRange
    },
    displayedDate () {
      return dateStringFormat(this.date)
    }
  },
  created () {
    this.date = this.multiple ? this.tableDateRange[0] : this.course.dueDate
  },
  methods: {
    ...mapActions({
      editCourseDueDate: 'courses/editCourseDueDate',
      editMultipleCoursesDueDate: 'courses/editMultipleCoursesDueDate',
      editAllCoursesDueDate: 'courses/editAllCoursesDueDate'
    }),
    hasDateChanged (date) {
      return date !== dateStringFormat(this.tableDateRange[0]) || 'Nie zmieniono daty'
    },
    updateDueDate () {
      if (this.$refs.updateDueDateForm.validate()) {
        if (this.allCourses) {
          this.editAllCoursesDueDate({ dueDate: this.date })
        } else if (this.multiple) {
          this.editMultipleCoursesDueDate({ dueDate: this.date })
        } else {
          this.editCourseDueDate({ id: this.course.id, dueDate: this.date })
        }
      }
    }
  }
}
</script>
